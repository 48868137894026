import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { AgeVerification } from '@modules/AgeVerification'
import { useStateContext } from '@context/stateContext'
import Navbar from '@components/elements/Navbar'
import Footer from '@components/elements/Footer'
import Modal from '@components/elements/Modal/Modal'
import components from '../content/common'
import { AnimatePresence } from 'framer-motion'
import LandingPageContent from '../components/modules/LandingPageContent'
//import ImagePopup from '../components/modules/ImagePopup'
import { graphql } from "gatsby";
import { I18nextContext } from "gatsby-plugin-react-i18next";

const Container = styled.div`
  filter: ${(props) => (props.modal ? 'blur(4px)' : 'none')};
  image-rendering: ${(props) =>
    props.isChrome ? '-webkit-optimize-contrast !important;' : 'undefined'};
`
const Section = tw.section`w-full bg-radler pt-28 md:pt-24 lg:px-2.5 lg:pt-[136px] overflow-hidden`
const IndexPage = () => {
  const { language } = React.useContext(I18nextContext);
  const {
    modal,
    modalHandle,
    //imagePopup,
    //imagePopupHandle,
    thirdScreenActive: landingPageActive,
    sticky,
    section,
  } = useStateContext()

  useEffect(() => {
    if (modal) {
      document.documentElement.style.overflowY = 'hidden'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
    }
  }, [modal])
  /*
  useEffect(() => {
    if(landingPageActive && language === 'de'){
      setTimeout(() => {
        imagePopupHandle(true)
      }, 3500);
    }
  }, [landingPageActive])
  */
  const isChrome = typeof window !== 'undefined' && !!window.chrome
  return (
    <>
      <Container modal={modal/* || (landingPageActive && imagePopup)*/} isChrome={isChrome}>
        {!section && <Navbar content={components.navbar} />}
        <Section className={`${landingPageActive && 'mb-5 lg:mb-7'}`}>
          <AgeVerification content={components.navbar} />
        </Section>
        {landingPageActive && <LandingPageContent />}
      </Container>
      <AnimatePresence exitBeforeEnter>
        {modal && <Modal key="modal" handleClose={() => modalHandle(false)} />}
        {/*imagePopup && landingPageActive && <ImagePopup key="image-popup" handleClose={() => imagePopupHandle(false)} />*/}
      </AnimatePresence>
      <Footer stickyVisible={sticky} />
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
