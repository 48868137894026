import React from 'react'
import Navbar from '../../components/elements/Navbar'
import { Headline, Section, Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import tw from 'twin.macro'


const IndexPage = () => {
  return (
    <>
      <Navbar content={components.navbar} />
      <Section>
        <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
          <Headline>Legal notice</Headline>
          <Subtitle>Legal notice</Subtitle>
          <p>
            Privatbrauerei ERDINGER Weißbräu Werner Brombach GmbH
            <br />
            Lange Zeile 1 und 3
            <br />
            85435 Erding
            <br />
            Telefon: <a href="tel:+49 (8122) 409 0">+49 (8122) 409 0</a>
            <br />
            Telefax: <a href="fax:+49 (8122) 409 115">+49 (8122) 409 115</a>
          </p>
          <p>
            Registered
            <br />
            Erding/OBB
            <br />
            85435 Erding
            <br />
            Amtsgericht München HRB 86831
          </p>
          <p>
            Ust-Ident-Nummer:
            <br />
            DE 811 220 486
          </p>
          <p>
            Owner:
            <br />
            Werner Brombach
          </p>
          <p>
            Managing Directors:
            <br />
            Dr. Stefan Kreisz - Chief Executive Officer
            <br />
            Dr. Stefan Huckemann
            <br />
            Wolfgang Kuffner
          </p>
          <p>
            Internet agency
            <br />
            Catenate GmbH
            <br />
            Schleißheimer Straße 156 Rgb.
            <br />
            80797 München
            <br />
            <a tw="" href="https://www.catenate.com" target="_blank">
              www.catenate.com
            </a>
            <br />
          </p>
          <p>
            <Subtitle>Disclaimer</Subtitle>
            <br />
            <b>Contents</b>
            <br />
            The contents of these pages has been carefully prepared and checked. ERDINGER Weißbräu however accepts no
            guarantee for the timeliness, correctness, completeness or quality of the information which has been
            provided.

            The document you are now reading is a translation from German into English. Should differences arise in the
            interpretation of different language versions, the original text in German is binding, not this translation.

            Claims against ERDINGER Weißbräu which are based on damages of a material or conceptual nature, and which
            have resulted from the use or failure to use the information provided, or as a result of faulty or
            incomplete information, are basically excluded, in so far as no verifiable intentional or grossly negligent
            blame on the part of ERDINGER Weißbräu is involved.

            ERDINGER Weißbräu reserves expressly the right, without prior notice, to change, to extend, or to annul
            parts of these pages, or everything offered, or to discontinue this complete publication.
          </p>
          <p>
            <b>Copyright and trademarks</b>
            <br />
            ERDINGER Weißbräu endeavors to observe valid copyrights in all publications. Should the infringement of a
            copyright arise in spite of this, ERDINGER Weißbräu will, having been made aware of the matter, remove the
            appropriate passage from its publication, or make a reference in it to the corresponding copyright.

            All trademarks mentioned in this Internet publication, including those from third parties, are subject
            without limitation to the provisions of the corresponding valid copyright legislation, and to the rights of
            possession of the corresponding registered copyright owners. From the mention alone of a copyright, the
            conclusion is not to be drawn that the copyright is not protected by the rights of a third party.

            The goods offered in our shop program are ERDINGER Weißbräu merchandising articles which bear the original
            ERDINGER Weißbräu logo and/or lettering. All logos, photographs and other images on the website or in the
            online catalog are protected by trademarks or copyright. All use without the permission of Privatbrauerei
            ERDINGER Weissbräu Werner Brombach GmbH, and in particular the unauthorized downloading of images, is
            prohibited and will be prosecuted under civil or criminal law.

            ERDINGER Weißbräu has the sole copyright for its own content on the ERDINGER Weißbräu website. The
            reproduction of such graphics, sounds or texts in other electronic or printed media is not permitted without
            the express consent of ERDINGER Weißbräu.
          </p>
          <p>
            <Subtitle>Terms of use</Subtitle>
            <br />
            ERDINGER Weißbräu Werner Brombach GmbH has taken reasonable care to ensure that all information made on this
            website is correct at the time of entering; nevertheless, accidental and occasional errors may still occur.
            Should this be the case, ERDINGER Weißbräu asks for your understanding.

            ERDINGER Weißbräu makes no representations or warranties about the information provided on this website and
            reserves the right to make changes and corrections at any time without prior notice. ERDINGER Weißbräu
            assumes no liability for inaccuracies or omissions on this website, and visitors are solely responsible
            themselves for all decisions made on the basis of information contained on this website. To the full extent
            permitted by law, ERDINGER Weißbräu assumes no liability for any direct, specific, indirect or consequential
            damages or losses or any other damage or of loss of any kind that may have arisen, for whatever reason,
            through the use of information taken directly or indirectly from this website. This does not affect your
            rights as a consumer under state legislation or any other laws which may not be excluded. The general terms
            and conditions for membership in Team ERDINGER Alkoholfrei are not affected by this.

            This website contains hyperlinks to other websites in order to make it easier for visitors. ERDINGER
            Weißbräu is only responsible for this external content if ERDINGER Weißbräu is aware of such content (i.e.
            also of illegal or criminal content), and it is technically possible and reasonable for ERDINGER Weißbräu to
            prevent its use. However, links are always live (dynamic) references. Although ERDINGER Weißbräu checked the
            external content during initial linking in order to establish possible civil or criminal responsibility,
            ERDINGER Weißbräu is not obliged to constantly check the content to which ERDINGER Weißbräu refers in its
            offer with regard to changes that could give rise to new liability.

            ERDINGER Weißbräu owns the copyright for the information and picture material on this website. Copying pages
            of this website for commercial purposes is strictly prohibited. Copying pages of this website for
            non-commercial purposes is only approved if all copies are marked with the copyright notice or other
            proprietary notices and disclaimers. No other rights for the use of copyrights, trademarks or patents of
            ERDINGER Weißbräu Werner Brombach GmbH will be issued. The product names mentioned herein are trademarks of
            ERDINGER Weißbräu; the exception are those trademarks which are indicated as owned by third parties.

            A reference to any product on this website does not constitute an offer for sale or supply of this product
            and does not mean that this product is available in all countries or that the name or description of the
            product is the same as that stated on the website.
          </p>
          <p>
            <b>Legal validity</b>
            <br />
            This exclusion of liability is to be considered as part of the Internet offer to which reference has been
            made on this page. In so far as parts of this text, or individual formulations in it, do not conform, no
            longer conform, or incompletely conform to the current legal position, the remaining parts of the document
            remain thereby unaffected in their content and their validity.
          </p>
        </div>
      </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default IndexPage
