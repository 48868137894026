import tw, { styled } from "twin.macro";
import React, {useState} from "react";
import { motion } from 'framer-motion'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { notSafari } from './LandingHeader'

import bastiKathiGlas from '@images/EBH_basti&kathi_glasl.png'
import glassPack from '@images/glass_pack.png'
import bastiKathiMobile from '@images/kathi-basti_mobile.png'
import glassPackMobile from '@images/pack_mobile.png'
import glassPackEN from '@images/pack_en.png'
import glassPackMobileEN from '@images/pack_mobile_en.png'
import {I18nextContext, useTranslation} from "gatsby-plugin-react-i18next";

const Section = tw.section`lg:mx-8 lg:px-2.5 relative pb-2 pt-10 xs:pt-20 xs:pb-10`
const Headline = tw.div`px-5 text-center text-2xl md:text-4xl font-lobster text-gray tracking-[0.02em] pb-4 lg:pb-10`
const Container = tw.div`bg-green relative w-full h-[625px] lg:h-[650px] 3xl:h-[700px] fhd:h-[750px] 2k:h-[800px]`
const Subheadline = tw.h2`text-32px lg:text-6xl 2k:text-75pxv2 text-center text-white font-thin leading-[140%]`
const ButtonShape = styled(motion.button)`
  ${tw`bg-white relative flex items-center justify-center flex-col text-radler rounded h-16 w-[188px] md:w-[280px] 
  md:h-[106px] text-15px leading-none md:text-22px font-lobster`}
  transition: filter 0.3s ease-in-out;
  transform: translateZ(0);
  &::after {
    content: '';
    ${tw`inset-1.5 border border-radler absolute z-10 opacity-70`}
  }
`

const GlassProductSection = ({ content }) => {
  const {isTablet, isMaster} = useBreakpoint()
  const heavyAnimations = notSafari || isMaster
  const [wasHovered, setWasHovered] = useState(false)
  const { t } = useTranslation();

  const { language } = React.useContext(I18nextContext);

  return (
    <Section>
      <Headline>{content.headline}</Headline>
      <Container>
        <div tw="w-full 3xl:w-[47%] absolute z-10 3xl:z-0 top-[150px] 3xl:top-16 3xl:right-16 flex 3xl:block justify-center">
          <img
            src={glassPack}
            alt="Pack"
            tw="min-w-[472px] w-[472px] 3xl:w-full"
          />
        </div>
        <div tw="absolute w-full 3xl:w-[47%] bottom-16 3xl:right-0 flex flex-col justify-center items-center gap-10">
          <Subheadline
            dangerouslySetInnerHTML={{
              __html: content.subheadline,
            }}
          />
          <ButtonShape
            onClick={() =>
              window.open(
                language === 'de'
                  ? 'https://shop.erdinger-fanshop.de/de/glaser-kruge.html'
                  : 'https://shop.erdinger-fanshop.de/en/glasses-mugs.html',
                '_blank'
              )
            }
            variants={buttonVariants}
            heavyAnimations={heavyAnimations}
            initial="initial"
            animate={wasHovered ? 'afterHover' : 'animate'}
            whileHover="hover"
            onHoverStart={() => {
              setWasHovered(true)
            }}
          >
            <span tw="text-18px leading-none md:text-32px">{t('BUY_NOW')}</span>
          </ButtonShape>
        </div>
        <div tw="absolute -top-4 3xl:top-auto 3xl:bottom-0 3xl:left-[-60px] w-full 3xl:max-h-[800px] 3xl:max-w-[64%] flex 3xl:block justify-center">
          <img
            src={isMaster ? bastiKathiGlas : bastiKathiMobile}
            alt="BastiKathi"
            tw="min-w-[396px] w-[396px] 3xl:w-full "
          />
        </div>
      </Container>
    </Section>
  )
}

const buttonVariants = {
  initial: {
    filter: 'drop-shadow(0 0 0 white)',
  },
  animate: {
    filter: [
      'drop-shadow(0 0 0 white)',
      'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
      'drop-shadow(0 0 0 white)',
      'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
      'drop-shadow(0 0 0 white)',
    ],
    transition: {
      ease: 'easeInOut',
      times: [0, 0.7, 1.4],
      repeatDelay: 4,
      repeat: Infinity,
    },
  },
  hover: {
    filter: 'drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  afterHover: {
    filter: 'drop-shadow(0 0 0 white)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
}

export default GlassProductSection
