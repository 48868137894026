import React from 'react'
import tw, { styled } from 'twin.macro'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

import hpHeader from '@images/handelspartner/hp-header.png'
import hpFlaschenpost from '@images/handelspartner/logo_flaschenpost.svg'

const Section = tw.section`w-full bg-radler pt-20 lg:px-2.5 lg:pt-[100px] overflow-hidden relative font-lobster text-white`

const ButtonShape = styled(motion.button)`
  ${tw`bg-white relative flex items-center justify-center flex-col text-radler rounded h-16 w-[188px] md:w-[280px] 
  md:h-[82px] text-22px leading-none md:text-32px font-lobster m-0`}
  transition: filter 0.3s ease-in-out;
  transform: translateZ(0);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  &::after {
    content: '';
    ${tw`inset-1.5 border border-radler absolute z-10 opacity-70`}
  }
`

const buttonVariants = {
  intial: {
    filter: 'drop-shadow(0 0 0 white)',
  },
  hover: {
    filter: 'drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  afterHover: {
    filter: 'drop-shadow(0 0 0 white)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
}

const HandelspartnerHeader = () => {
  const [wasHovered, setWasHovered] = React.useState(false)

  return (
    <>
      <style jsx>{`
        .hp-header {
          width: 493px;
          max-width: 100%;
          min-width: 170px;
        }
      `}</style>
      <Section>
        <div className="flex justify-center block pt-16 lg:hidden">
          <div>
            <div className="text-3xl">Direkt online kaufen</div>
            <div className="mt-1 text-22px">und nach Hause liefern lassen:</div>
          </div>
        </div>
        <div className="flex items-end justify-center">
          <div className="flex items-end justify-end flex-grow-0 w-2/4 mr-1 lg:mr-5">
            <img className="hp-header" tw="z-20" src={hpHeader} alt="Handelspartner" />
          </div>
          <div className="flex-grow-0 w-2/4 pr-5 mb-10 ml-1 lg:ml-5">
            <div className="hidden text-50px lg:block">Direkt online kaufen</div>
            <div className="hidden mt-1 text-3xl lg:block">und nach Hause liefern lassen:</div>
            <div className="mt-7">
              <ButtonShape
                  onClick={()=> window.open("https://www.flaschenpost.de/erdinger-brauhaus", "_blank")}
                  variants={buttonVariants}
                  initial="initial"
                  animate={wasHovered ? 'afterHover' : 'animate'}
                  whileHover="hover"
                  onHoverStart={() => {
                    setWasHovered(true)
                  }}
                >
                  Hier bestellen
              </ButtonShape>
            </div>
            <div className="mt-6 text-32px">
              <span className="hidden lg:inline">mit </span>
              <img className="inline" src={hpFlaschenpost} alt="flaschenpost.de" />
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default HandelspartnerHeader
