import React from 'react'
import tw from 'twin.macro'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import gebindeHellesKasten from '@images/handelspartner/gebinde/HELLES_Kasten.png'
import gebindeHellesSixpack330 from '@images/handelspartner/gebinde/HELLES_Sixpack_330.png'
import gebindeHellesSixpack500 from '@images/handelspartner/gebinde/HELLES_Sixpack_500.png'
import gebindeHellesDose500 from '@images/handelspartner/gebinde/HELLES_Dose_500.png'
import gebindeNaturradlerKasten from '@images/handelspartner/gebinde/NATURRADLER_Kasten.png'
import gebindeNaturradlerSixpack330 from '@images/handelspartner/gebinde/NATURRADLER_Sixpack_330.png'
import gebindeNaturradlerSixpack500 from '@images/handelspartner/gebinde/NATURRADLER_Sixpack_500.png'
import gebindeNaturradlerDose500 from '@images/handelspartner/gebinde/NATURRADLER_Dose_500.png'
import gebindeHellesAlkoholfreiKasten from '@images/b_helles_free_01.png'

const Section = tw.section`w-full py-10 overflow-hidden justify-evenly items-center flex`

const HandelspartnerGebinde = () => {
  return (
    <>
      <style jsx>{`
        .hp-gebinde-wrapper {
          background: -moz-linear-gradient(
            top,
            rgba(196, 196, 196, 0) 7.81%,
            rgba(196, 196, 196, 0.54) 100%
          ); /* FF3.6-15 */
          background: -webkit-linear-gradient(
            top,
            rgba(196, 196, 196, 0) 7.81%,
            rgba(196, 196, 196, 0.54) 100%
          ); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(
            to bottom,
            rgba(196, 196, 196, 0) 7.81%,
            rgba(196, 196, 196, 0.54) 100%
          ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c4c4c4', endColorstr='#8ac4c4c4',GradientType=0 ); /* IE6-9 */

          .swiper-slide {
            width: auto !important;
          }
        }
        .hp-gebinde-wrapper img {
          max-width: 100%;
          padding: 0 1em;
        }
        .hp-gebinde-kasten {
          max-height: 166px;
        }
        .hp-gebinde-sixpack330 {
          max-height: 147px;
        }
        .hp-gebinde-sixpack500 {
          max-height: 180px;
        }
        .hp-gebinde-dose500 {
          max-height: 147px;
        }
      `}</style>
      <Section className="hp-gebinde-wrapper">
        <Swiper
          loop={true}
          spaceBetween={30}
          slidesPerView={'auto'}
          onSwiper={(swiper) => swiper.slidesPerViewDynamic()}
        >
          <SwiperSlide>
            <img
              className="hp-gebinde-kasten"
              src={gebindeNaturradlerKasten}
              alt="ERDINGER Brauhaus Natur Radler Kasten"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-sixpack330"
              src={gebindeNaturradlerSixpack330}
              alt="ERDINGER Brauhaus Natur Radler Sixpack 0,33l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-sixpack500"
              src={gebindeNaturradlerSixpack500}
              alt="ERDINGER Brauhaus Natur Radler Sixpack 0.5l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-dose500"
              src={gebindeNaturradlerDose500}
              alt="ERDINGER Brauhaus Natur Radler Dose 0.5l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-dose500"
              src={gebindeHellesDose500}
              alt="ERDINGER Brauhaus Helles Dose 0.5l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-sixpack500"
              src={gebindeHellesSixpack500}
              alt="ERDINGER Brauhaus Helles Sixpack 0.5l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-sixpack330"
              src={gebindeHellesSixpack330}
              alt="ERDINGER Brauhaus Helles Sixpack 0,33l"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-kasten"
              src={gebindeHellesKasten}
              alt="ERDINGER Brauhaus Helles Kasten"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className="hp-gebinde-kasten"
              src={gebindeHellesAlkoholfreiKasten}
              alt="ERDINGER Brauhaus Helles Alkoholfrei Kasten"
            />
          </SwiperSlide>
        </Swiper>
      </Section>
    </>
  )
}

export default HandelspartnerGebinde
