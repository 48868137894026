exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-combi-js": () => import("./../../../src/pages/combi.js" /* webpackChunkName: "component---src-pages-combi-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-en-dataprotectionterms-js": () => import("./../../../src/pages/en/dataprotectionterms.js" /* webpackChunkName: "component---src-pages-en-dataprotectionterms-js" */),
  "component---src-pages-en-legal-notice-js": () => import("./../../../src/pages/en/legal-notice.js" /* webpackChunkName: "component---src-pages-en-legal-notice-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-famila-js": () => import("./../../../src/pages/famila.js" /* webpackChunkName: "component---src-pages-famila-js" */),
  "component---src-pages-handelspartner-js": () => import("./../../../src/pages/handelspartner.js" /* webpackChunkName: "component---src-pages-handelspartner-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klaasundkock-js": () => import("./../../../src/pages/klaasundkock.js" /* webpackChunkName: "component---src-pages-klaasundkock-js" */),
  "component---src-pages-presse-bpong-js": () => import("./../../../src/pages/presse/bpong.js" /* webpackChunkName: "component---src-pages-presse-bpong-js" */),
  "component---src-pages-presse-brass-sonntag-js": () => import("./../../../src/pages/presse/brass-sonntag.js" /* webpackChunkName: "component---src-pages-presse-brass-sonntag-js" */),
  "component---src-pages-presse-darts-wm-24-js": () => import("./../../../src/pages/presse/darts-wm-24.js" /* webpackChunkName: "component---src-pages-presse-darts-wm-24-js" */),
  "component---src-pages-presse-doppelstock-js": () => import("./../../../src/pages/presse/doppelstock.js" /* webpackChunkName: "component---src-pages-presse-doppelstock-js" */),
  "component---src-pages-presse-einhell-js": () => import("./../../../src/pages/presse/einhell.js" /* webpackChunkName: "component---src-pages-presse-einhell-js" */),
  "component---src-pages-presse-festl-bad-reichenhall-js": () => import("./../../../src/pages/presse/festl-bad-reichenhall.js" /* webpackChunkName: "component---src-pages-presse-festl-bad-reichenhall-js" */),
  "component---src-pages-presse-festl-oberammergau-js": () => import("./../../../src/pages/presse/festl-oberammergau.js" /* webpackChunkName: "component---src-pages-presse-festl-oberammergau-js" */),
  "component---src-pages-presse-festl-oberaudorf-js": () => import("./../../../src/pages/presse/festl-oberaudorf.js" /* webpackChunkName: "component---src-pages-presse-festl-oberaudorf-js" */),
  "component---src-pages-presse-festl-ruhpolding-2024-js": () => import("./../../../src/pages/presse/festl-ruhpolding-2024.js" /* webpackChunkName: "component---src-pages-presse-festl-ruhpolding-2024-js" */),
  "component---src-pages-presse-festl-ruhpolding-js": () => import("./../../../src/pages/presse/festl-ruhpolding.js" /* webpackChunkName: "component---src-pages-presse-festl-ruhpolding-js" */),
  "component---src-pages-presse-js": () => import("./../../../src/pages/presse.js" /* webpackChunkName: "component---src-pages-presse-js" */),
  "component---src-pages-presse-spendenscheckuebergabe-js": () => import("./../../../src/pages/presse/spendenscheckuebergabe.js" /* webpackChunkName: "component---src-pages-presse-spendenscheckuebergabe-js" */),
  "component---src-pages-radlertour-js": () => import("./../../../src/pages/radlertour.js" /* webpackChunkName: "component---src-pages-radlertour-js" */),
  "component---src-pages-teilnahmebedingungen-js": () => import("./../../../src/pages/teilnahmebedingungen.js" /* webpackChunkName: "component---src-pages-teilnahmebedingungen-js" */),
  "component---src-pages-tnb-gewinnspiel-carlic-land-festival-2024-js": () => import("./../../../src/pages/tnb/gewinnspiel-carlic-land-festival-2024.js" /* webpackChunkName: "component---src-pages-tnb-gewinnspiel-carlic-land-festival-2024-js" */),
  "component---src-pages-tnb-gewinnspiel-combi-2024-js": () => import("./../../../src/pages/tnb/gewinnspiel-combi-2024.js" /* webpackChunkName: "component---src-pages-tnb-gewinnspiel-combi-2024-js" */),
  "component---src-pages-tnb-gewinnspiel-famila-2024-js": () => import("./../../../src/pages/tnb/gewinnspiel-famila-2024.js" /* webpackChunkName: "component---src-pages-tnb-gewinnspiel-famila-2024-js" */),
  "component---src-pages-tnb-gewinnspiel-klaasundkock-2024-js": () => import("./../../../src/pages/tnb/gewinnspiel-klaasundkock-2024.js" /* webpackChunkName: "component---src-pages-tnb-gewinnspiel-klaasundkock-2024-js" */)
}

