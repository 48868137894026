import React, { useEffect } from 'react'
import TypeSelection from '@components/modules/TypeSelection'
import BeerSection from '@components/modules/BeerSection'
import StoriesSection from '@components/modules/StoriesSection'
import sections from '../../content/beerSection'
import story from '../../content/storySection'
import glassSection from '../../content/glassSection'
import { useStateContext } from '../../context/stateContext'
import { AnimatePresence } from 'framer-motion'
import StickyBottom from '../elements/StickyBottom'
import components from '../../content/common'
import EventsSection from './EventsSection'
import VideoSection from './VideoSection'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import GlassProductSection from "./GlassProductSection";

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item)
}

const LandingPageContent = () => {
  const [sectionCount, setSectionCount] = React.useState(null)
  const sectionsRef = React.useRef([])
  sectionsRef.current = [...Array(5).keys()].map(
    (_, i) => sectionsRef.current[i] ?? React.createRef()
  )
  const { modal, sticky } = useStateContext()
  const { language } = React.useContext(I18nextContext);

  useEffect(() => {
    let sectionBreakpoints = Array(5).fill(0)

    const measureSections = () => {
      sectionBreakpoints = sectionsRef.current.map((section, index) => {
        if (index === 0) {
          const { height, top } = section.current.getBoundingClientRect()
          return top + height + window.scrollY + 150
        }
        const { height, top } = section.current.getBoundingClientRect()
        return top + height * 0.5 + window.scrollY
      })
    }

    const scrollHandler = () => {
      const { innerHeight, scrollY } = window
      const scrollVal = scrollY + innerHeight
      for (let i = 0; i < sectionBreakpoints.length; i++) {
        if (scrollVal <= sectionBreakpoints[0]) {
          setSectionCount(null)
        }
        if (
          scrollVal >= sectionBreakpoints[i] &&
          scrollVal <= sectionBreakpoints[i + 1]
        ) {
          setSectionCount(i + 1)
        }
        if (scrollVal >= sectionBreakpoints[sectionBreakpoints.length - 1]) {
          setSectionCount(sectionBreakpoints.length)
        }
      }
    }

    measureSections()
    scrollHandler()
    window.addEventListener('resize', measureSections)
    window.addEventListener('scroll', scrollHandler, false)
    return () => {
      window.removeEventListener('resize', measureSections)
      window.removeEventListener('scroll', scrollHandler, false)
    }
  }, [])

  return (
    <>
      <div ref={sectionsRef.current[0]}>
        <TypeSelection language={language} />
      </div>
      <div ref={sectionsRef.current[3]}>
        <BeerSection
          content={sections[language || 'de'][1]}
          id="helles"
          language={language || 'de'}
        />
      </div>
      <div ref={sectionsRef.current[2]}>
        <BeerSection
          content={sections[language || 'de'][2]}
          id="helles-alkoholfrei"
          language={language || 'de'}
        />
      </div>
      <div ref={sectionsRef.current[1]}>
        <BeerSection
          content={sections[language || 'de'][0]}
          id="radler"
          language={language || 'de'}
        />
      </div>
      {/*language === 'de' && <EventsSection />*/}
      <GlassProductSection content={glassSection[language || 'de'][0]} />
      {language === 'de' && <VideoSection />}
      <div ref={sectionsRef.current[4]}>
        <StoriesSection content={story} />
      </div>
      {language === 'de' && (
        <AnimatePresence exitBeforeEnter>
          {sticky && !modal && !!sectionCount && (
            <StickyBottom
              key="sticky-bottom"
              content={components.stickyBottom}
              section={sectionCount}
            />
          )}
        </AnimatePresence>
      )}
    </>
  )
}

export default LandingPageContent
