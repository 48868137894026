import React, { useState } from 'react'
import tw, { styled } from 'twin.macro'
import Headline from '@elements/Headline'
import { MotionFadeZoom } from '@elements/Animations'
import { useBreakpoint } from '@hooks/useBreakpoint'
//import { useStateContext } from '@context/stateContext'
import { motion } from 'framer-motion'
import {
  helles,
  radler,
  hopfen_01,
  lemon_01,
  lemon_02,
  radler_shadow,
  helles_shadow,
  lemon_shadow,
  hopfen_shadow,
  bottle_shadow,
  hellesAlkoholfrei,
  badge_new,
  //signet_01,
} from '@images/landing'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'

const ButtonShape = styled(motion.button)`
  ${tw`bg-white relative flex items-center justify-center flex-col text-radler rounded h-16 w-[188px] md:w-[280px] 
  md:h-[106px] text-15px leading-none md:text-22px font-lobster`}
  transition: filter 0.3s ease-in-out;
  transform: translateZ(0);
  &::after {
    content: '';
    ${tw`inset-1.5 border border-radler absolute z-10 opacity-70`}
  }
`
const MobileButtonWrapper = styled(MotionFadeZoom)`
  ${tw`absolute lg:relative top-[375px] md:top-[700px] left-1/2 -translate-x-1/2 z-10`}

  @media screen and (max-width: 767px) and (min-height: 664px) {
    top: 400px;
  }
`

const AnimationWrapper = styled(motion.div)`
  ${tw`relative flex justify-center items-center 3xl:ml-10 h-[668px] w-[650px] flex-shrink-0
  scale-40 md:scale-65 lg:scale-75 xl:scale-[0.8] 3xl:scale-100 origin-top lg:origin-center`};
`
const Glow = styled(motion.div)`
  ${tw`absolute bottom-[20%] left-0 h-1/3 bg-white lg:w-1/2 macbook:w-2/3 3xl:w-3/4 3xl:bottom-1/4 3xl:h-1/4 blur-[214px] opacity-[0.3] z-[-1] hidden lg:block lg:translate-x-24 macbook:translate-x-32 2xl:translate-x-40`}
  transform: translateZ(0);
`
const MotionImage = styled(motion.img)`
  ${tw`absolute bottom-0 left-0 object-contain`};
`

const RadlerButton = ({ onClick, className, heavyAnimations, ...props }) => {
  const [wasHovered, setWasHovered] = useState(false)
  const { t } = useTranslation()
  if (heavyAnimations) {
    return (
      <ButtonShape
        className={className}
        onClick={onClick}
        {...props}
        variants={buttonVariants}
        initial="initial"
        animate={wasHovered ? 'afterHover' : 'animate'}
        whileHover="hover"
        onHoverStart={() => {
          setWasHovered(true)
        }}
      >
        <span tw="text-18px leading-none md:text-32px">{t('BUY_NOW')}</span>
      </ButtonShape>
    )
  }
  return (
    <ButtonShape
      className={className}
      onClick={onClick}
      {...props}
      variants={buttonVariants}
      initial="initial"
      animate={{
        filter: [
          'drop-shadow(0 0 0 white)',
          'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
          'drop-shadow(0 0 0 white)',
          'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
          'drop-shadow(0 0 0 white)',
        ],
        transition: {
          ease: 'easeInOut',
          times: [0, 0.7, 1.4],
          delay: 2.85,
        },
      }}
    >
      <span tw="text-18px leading-none md:text-32px">{t('BUY_NOW')}</span>
    </ButtonShape>
  )
}
const isSafari = () => navigator.userAgent.indexOf('Safari') !== -1

export const notSafari =
  typeof window !== 'undefined' && (!isSafari() || !!window.chrome)

export const Landing = () => {
  const { isTablet, isMaster } = useBreakpoint()
  const heavyAnimations = notSafari || isMaster
  const gotoHandelspartner = () => (window.location = '/handelspartner')
  const goToFlaschenpost = () =>
    window.open('https://www.flaschenpost.de/erdinger-brauhaus', '_blank')
  const { language } = React.useContext(I18nextContext)

  //manually controlling animation state, 0 - initial, 1-entry animation, 2-loop
  const [subheadlineState, setSubheadlineState] = useState(0)
  const { t } = useTranslation()

  return (
    <div tw="relative z-10 h-full w-full flex justify-center items-center">
      <div tw="flex flex-col justify-start h-full w-full lg:justify-between lg:flex-row items-center max-w-[900px] xl:max-w-[920px] 3xl:max-w-master">
        <div tw="flex flex-col justify-start items-center">
          <motion.div
            tw="flex flex-col justify-center items-center lg:items-start"
            variants={CTAcontainer}
            initial="hidden"
            animate="show"
          >
            <Headline
              variants={CTAitem}
              label={t('STH_SPECIAL')}
              tw="text-32px xxs:text-40px md:text-90 mb-0 2xl:mb-1.5 max-w-[355px] w-max text-center lg:text-left"
            />
            {heavyAnimations ? (
              <motion.p
                onAnimationStart={() => {
                  subheadlineState === 0 && setSubheadlineState(1)
                }}
                onAnimationComplete={() => {
                  subheadlineState === 1 && setSubheadlineState(2)
                }}
                initial={{ opacity: 0, scale: 0.9 }}
                animate={
                  subheadlineState === 0
                    ? { opacity: 0, scale: 0.9 }
                    : subheadlineState === 1
                    ? {
                        opacity: 1,
                        scale: 1,
                        transition: {
                          type: 'tween',
                          ease: 'easeInOut',
                          duration: 0.3,
                          delay: 1.75,
                        },
                      }
                    : {
                        opacity: 1,
                        scale: [1, 1.12, 1],
                        transition: {
                          ease: 'easeInOut',
                          duration: 0.5,
                          delay: 2.85,
                          repeatDelay: 4.3,
                          repeat: Infinity,
                        },
                      }
                }
                tw="relative z-30 font-lobster text-25px md:text-50px mb-4
              text-white lg:mb-10 max-w-[329px] w-max text-center lg:text-left transform-gpu"
              >
                {t('WANNA_TRY')}
              </motion.p>
            ) : (
              <motion.p
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transition: {
                    type: 'tween',
                    ease: 'easeInOut',
                    duration: 0.3,
                    delay: 1.75,
                  },
                }}
                tw="relative z-30 font-lobster text-25px md:text-50px mb-4
            text-white lg:mb-10 max-w-[329px] w-max text-center lg:text-left transform-gpu"
              >
                {t('WANNA_TRY')}
              </motion.p>
            )}

            {!isTablet && language === 'de' && (
              <MotionFadeZoom delay={2.2}>
                <RadlerButton
                  onClick={
                    language === 'de' ? gotoHandelspartner : goToFlaschenpost
                  }
                  heavyAnimations={heavyAnimations}
                />
              </MotionFadeZoom>
            )}
          </motion.div>
        </div>
        <AnimationWrapper tw="">
          <motion.div
            variants={bottleContainer}
            initial="hidden"
            animate="show"
          >
            {!isTablet && notSafari ? (
              <Glow
                variants={glowVariants}
                initial="hidden"
                animate={
                  subheadlineState === 0
                    ? 'hidden'
                    : subheadlineState === 1
                    ? 'show'
                    : 'loop'
                }
              />
            ) : (
              isMaster && <Glow />
            )}
            <MotionImage
              src={radler_shadow}
              tw="left-[12%] bottom-[3%] z-10 w-[194px]"
              variants={radlerVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={radler_shadow}
              tw="left-[38%] bottom-0 z-10 w-[194px]"
              variants={radlerVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={helles_shadow}
              tw="left-[26%] bottom-[2%] z-10 w-[500px]"
              variants={hellesVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={radler}
              tw="left-[64%] top-0 z-10 h-[637px] object-contain"
              variants={hellesVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={badge_new}
              tw="left-[-2%] top-[27%] z-10 h-[120px] w-[120px] object-contain"
              variants={badgeNewVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={bottle_shadow}
              tw="left-[45.25%] bottom-[1%] z-10 w-[75px]"
              variants={radlerVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={hellesAlkoholfrei}
              tw="left-[13%] top-0 z-10 h-[637px] object-contain"
              variants={radlerVariants}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={helles}
              tw="left-[38%] top-0 z-10 h-[656px] object-contain"
              variants={radlerVariants}
              initial="hidden"
              animate="show"
            />
          </motion.div>

          {/* //Lemons */}
          <motion.div variants={lemonContainer} initial="hidden" animate="show">
            <MotionImage
              src={lemon_shadow}
              tw="left-[unset] right-[-6%] bottom-[-1%] w-[250px] z-0"
              variants={lemon02}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={lemon_01}
              tw="left-[unset] right-[-11%] bottom-[3%] w-[188px] z-0"
              variants={lemon02}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={lemon_02}
              tw="left-[unset] right-[-12%] bottom-[1%] w-48 z-30"
              variants={lemon01}
              initial="hidden"
              animate="show"
            />
          </motion.div>
          <motion.div
            variants={hopfenContainer}
            initial="hidden"
            animate="show"
          >
            <MotionImage
              src={hopfen_shadow}
              tw="right-[unset] bottom-[-1%] left-[-7%] z-30 w-[200px]"
              variants={hopfenItem}
              initial="hidden"
              animate="show"
            />
            <MotionImage
              src={hopfen_01}
              tw="right-[unset] bottom-[0] left-[-4%] z-30 w-[124px]"
              variants={hopfenItem}
            />
          </motion.div>
          {/*!isTablet && language === 'de' && (
            <motion.div
              variants={signetContainer}
              initial="hidden"
              animate="show"
            >
              <MotionImage
                src={signet_01}
                tw="left-[0%] bottom-[35%] right-[unset] z-30 w-[150px]"
                variants={signetItem}
              />
            </motion.div>
          )}
          {isTablet && language === 'de' && (
            <motion.div
              variants={signetContainer}
              initial="hidden"
              animate="show"
            >
              <MotionImage
                src={signet_01}
                tw="left-[0%] bottom-[35%] right-[unset] z-30 w-[165px]"
                variants={signetItem}
              />
            </motion.div>
          )*/}
        </AnimationWrapper>
        {isTablet && language === 'de' && (
          <MobileButtonWrapper delay={2.2}>
            <RadlerButton
              onClick={
                language === 'de' ? gotoHandelspartner : goToFlaschenpost
              }
              heavyAnimations={heavyAnimations}
              tw="absolute lg:relative top-0 left-1/2 -translate-x-1/2"
            />
          </MobileButtonWrapper>
        )}
      </div>
    </div>
  )
}
const buttonVariants = {
  intial: {
    filter: 'drop-shadow(0 0 0 white)',
  },
  animate: {
    filter: [
      'drop-shadow(0 0 0 white)',
      'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
      'drop-shadow(0 0 0 white)',
      'drop-shadow(0px 0px 13px rgba(255, 255, 255, 0.8))',
      'drop-shadow(0 0 0 white)',
    ],
    transition: {
      ease: 'easeInOut',
      times: [0, 0.7, 1.4],
      repeatDelay: 4,
      repeat: Infinity,
    },
  },
  hover: {
    filter: 'drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  afterHover: {
    filter: 'drop-shadow(0 0 0 white)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
}
const bottleContainer = {
  hidden: { opacity: 0 },
  show: { opacity: 1 },
}

const radlerVariants = {
  hidden: { opacity: 0, y: '-20%' },
  show: {
    opacity: 1,
    y: '0%',
    transition: {
      type: 'spring',
      delay: 0.8,
      mass: 0.4,
      stiffnesss: 155,
      damping: 6,
    },
  },
}

const badgeNewVariants = {
  hidden: {
    opacity: 0,
    rotate: -90,
    scale: 0,
  },
  show: {
    opacity: 1,
    rotate: 0,
    scale: 1,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      delay: 1.4,
    },
  },
}

const glowVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 0.3,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      delay: 0.8,
    },
  },
  loop: {
    opacity: [0.3, 0.15, 0.3],
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 2,
      repeat: Infinity,
    },
  },
}

const hellesVariants = {
  hidden: { opacity: 0, y: '-15%' },
  show: {
    opacity: 1,
    y: '0%',
    transition: {
      type: 'spring',
      delay: 0.9,
      mass: 0.4,
      stiffnesss: 150,
      damping: 6,
    },
  },
}
const lemonContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.15,
      type: 'tween',
      ease: 'easeIn',
      delay: 0.95,
    },
  },
}

const lemon01 = {
  hidden: { y: '-55%' },
  show: {
    y: '0%',

    transition: {
      duration: 1.5,
      type: 'spring',
      bounce: 0.7,
      delay: 1,
    },
  },
}
const lemon02 = {
  hidden: { y: '-45%' },
  show: {
    y: '0%',
    transition: {
      duration: 1.5,
      type: 'spring',
      bounce: 0.7,
      delay: 1,
    },
  },
}

const hopfenContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.15,
      type: 'tween',
      ease: 'easeIn',
      delay: 1.2,
    },
  },
}

const hopfenItem = {
  hidden: { y: '-55%' },
  show: {
    y: '0%',
    transition: {
      duration: 1.5,
      type: 'spring',
      bounce: 0.6,
      delay: 1.2,
    },
  },
}

const CTAcontainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3,
      delayChildren: 0.45,
    },
  },
}

const CTAitem = {
  hidden: { scale: 0.9, opacity: 0 },
  show: {
    scale: 1,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.3,
    },
  },
}

/*
const signetContainer = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.15,
      type: 'tween',
      ease: 'easeIn',
      delay: 1.5,
    },
  },
}

const signetItem = {
  hidden: { y: '-55%' },
  show: {
    y: '0%',
    transition: {
      duration: 1.5,
      type: 'spring',
      bounce: 0.6,
      delay: 1.5,
    },
  },
}
*/
