import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import Navbar from '@components/elements/Navbar'
import Footer from '@components/elements/Footer'
import Modal from '@components/elements/Modal/Modal'
import components from '../content/common'
import { AnimatePresence } from 'framer-motion'
import HandelspartnerHeader from '@components/modules/HandelspartnerHeader'
import HandelspartnerGebinde from '@components/modules/HandelspartnerGebinde'
import HandelspartnerLogos from '@components/modules/HandelspartnerLogos'

const Container = styled.div`
  filter: ${(props) => (props.modal ? 'blur(20px)' : 'none')};
  image-rendering: ${(props) =>
    props.isChrome ? '-webkit-optimize-contrast !important;' : 'undefined'};
`

const ContentWrap = tw.div`max-w-master mx-auto pb-10 pt-5 sm:py-10 md:py-24 px-5`
const Heading = tw.h1`font-lobster text-green text-[22px] md:text-4xl tracking-[0.02em] md:leading-[50px] pb-5 md:pb-8`
const Text = tw.p`text-15px leading-[20px] text-gray font-roboto md:text-[24px] md:leading-[29px] lg:tracking-[0.02em] pb-5 md:pb-8`

const EventsPage = () => {
  const { modal, modalHandle, sticky } = useStateContext()

  useEffect(() => {
    if (modal) {
      document.documentElement.style.overflowY = 'hidden'
    }
    return () => {
      document.documentElement.style.overflowY = 'auto'
    }
  }, [modal])
  const isChrome = typeof window !== 'undefined' && !!window.chrome
  return (
    <>
      <Container modal={modal} isChrome={isChrome} className="bg-lightGray lg:mb-20">
        <Navbar content={components.navbar} />
        <HandelspartnerHeader />
        <HandelspartnerGebinde />
        <ContentWrap>
          <Heading>
            Unsere Handelspartner
          </Heading>
          <Text className="hidden lg:block">
            Bei den folgenden Handelspartnern findest du unser ERDINGER Brauhaus Helles, Alkoholfrei und NaturRadler. Die Verfügbarkeit vor Ort ist aber nicht garantiert. Frage das Personal beim Händler deines Vertrauens, sie werden dir ERDINGER Brauhaus Helles, ERDINGER Brauhaus Helles Alkoholfrei und ERDINGER Brauhaus NaturRadler sicher gerne besorgen.
          </Text>
          <Text className="block lg:hidden">
            Bei den folgenden Handelspartnern findest du unser ERDINGER Brauhaus Helles, Alkoholfrei und NaturRadler. *
          </Text>
          <HandelspartnerLogos />
          <Text className="flex items-start justify-start block mt-5 lg:hidden text-darkGray">
            <span className="mr-1">*</span>
            <span>
              Die Verfügbarkeit vor Ort ist aber nicht garantiert. Frage das Personal beim Händler deines Vertrauens, sie werden dir ERDINGER Brauhaus Helles, ERDINGER Brauhaus Helles Alkoholfrei und ERDINGER Brauhaus NaturRadler sicher gerne besorgen.
            </span>
          </Text>
        </ContentWrap>
      </Container>
      <AnimatePresence exitBeforeEnter>
        {modal && <Modal key="modal" handleClose={() => modalHandle(false)} />}
      </AnimatePresence>
      <Footer stickyVisible={sticky} />
    </>
    
  )
}

export default EventsPage
